<template>
<div
    class="kt-widget28"
    style="width: 100%"
>
    <div class="kt-widget28__wrapper kt-portlet__space-x">
        <div class="tab-content">
            <div
                class="tab-pane active row"
                style="display: flex;"
            >
                <div class="col-lg-6">
                    <div class="kt-widget28__tab-items">
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.template') }}</span>
                            <span>{{ template.gradeTemplateName }}</span>
                        </div>
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.scale') }}</span>
                            <span>{{ template.scale.scaleDescription }}</span>
                        </div>
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.averageMethod') }}</span>
                            <span>{{ template.averageMethod }}</span>
                        </div>
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.gradeScales') }}</span>
                            <span>
                                <span
                                    v-for="g in template.scale.grades"
                                    :key="'grd'+g.mark"
                                >
                                    <div style="margin: 10px 0">
                                        <span
                                            class="colorSwatch"
                                            :class="g.color"
                                        />
                                        {{ g.mark }}
                                        <code style="margin: 0 10px; color: #0571f7">{{ g.minGrade }}-{{ g.maxGrade }} %</code>
                                        <small class="text-muted">{{ g.displayName }}</small>
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="kt-widget28__tab-items">
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.type') }}</span>
                            <span>{{ template.properties.templateType == "school" ? "School Template" : "Course Template" }}</span>
                        </div>
                        <div
                            v-if="template.maxPoints"
                            class="kt-widget28__tab-item"
                        >
                            <span>Restrict Points</span>
                            <span>{{ template.maxPoints }}</span>
                        </div>
                        <div
                            v-if="template.maxWeight"
                            class="kt-widget28__tab-item"
                        >
                            <span>Restrict Weight</span>
                            <span>{{ template.maxWeight }}</span>
                        </div>
                        <div
                            v-if="template.floorAverages"
                            class="kt-widget28__tab-item"
                        >
                            <span>Floor Averages</span>
                            <span>{{ template.floorAverages }}</span>
                        </div>
                        <div class="kt-widget28__tab-item">
                            <span>Allow All Numerics</span>
                            <span>{{ template.allowAllNumerics }}</span>
                        </div>
                        <div class="kt-widget28__tab-item">
                            <span>{{ $t('components.gradeTemplateDescription.gradingCategories') }}</span>
                            <span>
                                <span
                                    v-for="c in template.categories"
                                    :key="'cat'+c.gradeTemplateCategoryId"
                                >
                                    <div style="margin: 10px 0">
                                        <span
                                            class="colorSwatch"
                                            :class="c.categoryColor"
                                        />
                                        {{ c.categoryName }}
                                        <code style="margin: 0 10px; color: #0571f7">{{ c.percentage }} %</code>
                                        <small
                                            v-if="c.dropLowest"
                                            class="text-muted"
                                            title="Drop Lowest"
                                        >{{ $t('components.gradeTemplateDescription.dropLowest') }}</small>
                                    </div>
                                </span>
                            </span>
                        </div>

                        <div class="kt-widget28__tab-item">
                            <span>Allowed Marks</span>
                            <span>
                                <span
                                    v-for="c in template.marks"
                                    :key="'mark_'+c.gradeTemplateMarkId"
                                >
                                    <div style="margin: 10px 0">
                                        <span
                                            class="colorSwatch"
                                            :class="c.gradeTemplateMarkColor"
                                        />
                                        {{ c.gradeTemplateMark }}
                                        <code
                                            v-if="c.numericPassthrough !== null"
                                            style="margin: 0 10px; color: #0571f7"
                                        >
                                            {{ c.numericPassthrough }} {{ template.averageMethod == 'Points' ? 'Points' : '%' }}
                                        </code>
                                        <code v-else>
                                            Excused
                                        </code>
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'GradeTemplateDescription',
    props: {
        template: {
            type: Object,
            required: true,
        },
    },
};

</script>
