<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader />

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        {{ $t('pages.teacher.gradeTemplate.template') }}:
                        {{ template?.gradeTemplateName || '' }}
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <button
                        v-if="googleCourse"
                        class="btn btn-clean btn-clean-primary btn-sm"
                        @click.stop.prevent="openGoogleCategoryModal()"
                    >
                        Assign Google Categories
                    </button>
                    <router-link
                        v-if="$_userMixins_isSchoolAdmin"
                        :to="{name: 'GradeTemplateList'}"
                        class="btn btn-clean btn-clean-primary btn-sm"
                    >
                        Edit Templates
                    </router-link>
                </div>
            </div>

            <div
                v-if="gradeTemplates.length > 0"
                class="kt-portlet__body"
            >
                <div
                    v-if="allowGradeTemplateEditing"
                    class="alert alert-outline-info fade show w-100"
                >
                    <div class="alert-icon">
                        <i class="flaticon-questions-circular-button" />
                    </div>
                    <div class="alert-text">
                        After changing a grade template, refresh all google data to apply the template.
                    </div>
                </div>

                <div
                    v-if="allowGradeTemplateEditing"
                    class="kt-portlet__content row"
                >
                    <div
                        style="width: 600px; max-width: 400px"
                        class="ml-4"
                    >
                        <GradeTemplateCourseUpdater
                            class="w-100"
                            :original-grade-template-id="template?.gradeTemplateId || null"
                            :course-section-id="course.courseSectionId"
                            :course="course"
                            :index="0"
                        />
                    </div>
                </div>

                <div class="kt-portlet__content">
                    <GradeTemplateDescription :template="template" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import GradeTemplateDescription from '../components/GradeTemplateDescription.vue';
import GradeTemplateCourseUpdater from '../components/GradeTemplateCourseUpdater.vue';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import Types from '../store/Types';
import portfolioMixins from '../store/mixins/portfolioMixins';
import userMixins from '../store/mixins/userMixins';
import CourseSubHeader from './CourseSubHeader.vue';

export default {
    name: 'CourseGradeTemplate',
    components: {
        CourseSubHeader,
        GradeTemplateDescription,
        GradeTemplateCourseUpdater,
    },
    mixins: [portfolioMixins, courseMixins, userMixins, googleCourseMixins],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            gradeTemplates: (state) => state.database.gradeTemplates,
            courseSectionGradeTemplates: (state) => state.database.courseSectionGradeTemplates,
            canChooseGradeTemplate: (state) => state.user.userPermissions.canChooseGradeTemplate,
        }),
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        template() {
            const { course } = this;
            return course.gradeTemplate || null;
        },
        allowGradeTemplateEditing() {
            if (this.user.school.role == 'School Admin') return true;
            return this.canChooseGradeTemplate;
        },
        canEditCourse() {
            return this.course.canEditCourse || false;
        },
    },
    watch: {
        '$route.params.extCourseSectionId': {
            handler() {
                this.key += 1;
            },
        },
    },
    methods: {
        openGoogleCategoryModal() {
            const v = this;
            v.$store.commit(Types.mutations.SHOW_GLOBAL_MODAL, 'google-cat-change-modal');
        },
    },
};
</script>
