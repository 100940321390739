var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-widget28", staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "kt-widget28__wrapper kt-portlet__space-x" }, [
        _c("div", { staticClass: "tab-content" }, [
          _c(
            "div",
            {
              staticClass: "tab-pane active row",
              staticStyle: { display: "flex" },
            },
            [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "kt-widget28__tab-items" }, [
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.gradeTemplateDescription.template")
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.template.gradeTemplateName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.gradeTemplateDescription.scale")
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.template.scale.scaleDescription)),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.gradeTemplateDescription.averageMethod"
                          )
                        )
                      ),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.template.averageMethod))]),
                  ]),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.gradeTemplateDescription.gradeScales"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "span",
                      _vm._l(_vm.template.scale.grades, function (g) {
                        return _c("span", { key: "grd" + g.mark }, [
                          _c("div", { staticStyle: { margin: "10px 0" } }, [
                            _c("span", {
                              staticClass: "colorSwatch",
                              class: g.color,
                            }),
                            _vm._v(" " + _vm._s(g.mark) + " "),
                            _c(
                              "code",
                              {
                                staticStyle: {
                                  margin: "0 10px",
                                  color: "#0571f7",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(g.minGrade) +
                                    "-" +
                                    _vm._s(g.maxGrade) +
                                    " %"
                                ),
                              ]
                            ),
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v(_vm._s(g.displayName)),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "kt-widget28__tab-items" }, [
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.gradeTemplateDescription.type")
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.template.properties.templateType == "school"
                            ? "School Template"
                            : "Course Template"
                        )
                      ),
                    ]),
                  ]),
                  _vm.template.maxPoints
                    ? _c("div", { staticClass: "kt-widget28__tab-item" }, [
                        _c("span", [_vm._v("Restrict Points")]),
                        _c("span", [_vm._v(_vm._s(_vm.template.maxPoints))]),
                      ])
                    : _vm._e(),
                  _vm.template.maxWeight
                    ? _c("div", { staticClass: "kt-widget28__tab-item" }, [
                        _c("span", [_vm._v("Restrict Weight")]),
                        _c("span", [_vm._v(_vm._s(_vm.template.maxWeight))]),
                      ])
                    : _vm._e(),
                  _vm.template.floorAverages
                    ? _c("div", { staticClass: "kt-widget28__tab-item" }, [
                        _c("span", [_vm._v("Floor Averages")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.template.floorAverages)),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [_vm._v("Allow All Numerics")]),
                    _c("span", [_vm._v(_vm._s(_vm.template.allowAllNumerics))]),
                  ]),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.gradeTemplateDescription.gradingCategories"
                          )
                        )
                      ),
                    ]),
                    _c(
                      "span",
                      _vm._l(_vm.template.categories, function (c) {
                        return _c(
                          "span",
                          { key: "cat" + c.gradeTemplateCategoryId },
                          [
                            _c("div", { staticStyle: { margin: "10px 0" } }, [
                              _c("span", {
                                staticClass: "colorSwatch",
                                class: c.categoryColor,
                              }),
                              _vm._v(" " + _vm._s(c.categoryName) + " "),
                              _c(
                                "code",
                                {
                                  staticStyle: {
                                    margin: "0 10px",
                                    color: "#0571f7",
                                  },
                                },
                                [_vm._v(_vm._s(c.percentage) + " %")]
                              ),
                              c.dropLowest
                                ? _c(
                                    "small",
                                    {
                                      staticClass: "text-muted",
                                      attrs: { title: "Drop Lowest" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.gradeTemplateDescription.dropLowest"
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("div", { staticClass: "kt-widget28__tab-item" }, [
                    _c("span", [_vm._v("Allowed Marks")]),
                    _c(
                      "span",
                      _vm._l(_vm.template.marks, function (c) {
                        return _c(
                          "span",
                          { key: "mark_" + c.gradeTemplateMarkId },
                          [
                            _c("div", { staticStyle: { margin: "10px 0" } }, [
                              _c("span", {
                                staticClass: "colorSwatch",
                                class: c.gradeTemplateMarkColor,
                              }),
                              _vm._v(" " + _vm._s(c.gradeTemplateMark) + " "),
                              c.numericPassthrough !== null
                                ? _c(
                                    "code",
                                    {
                                      staticStyle: {
                                        margin: "0 10px",
                                        color: "#0571f7",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(c.numericPassthrough) +
                                          " " +
                                          _vm._s(
                                            _vm.template.averageMethod ==
                                              "Points"
                                              ? "Points"
                                              : "%"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("code", [_vm._v(" Excused ")]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }