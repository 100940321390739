var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("CourseSubHeader"),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c("div", { staticClass: "kt-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c("h3", { staticClass: "kt-portlet__head-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("pages.teacher.gradeTemplate.template")) +
                      ": " +
                      _vm._s(_vm.template?.gradeTemplateName || "") +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "kt-portlet__head-toolbar" },
                [
                  _vm.googleCourse
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-clean btn-clean-primary btn-sm",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openGoogleCategoryModal()
                            },
                          },
                        },
                        [_vm._v(" Assign Google Categories ")]
                      )
                    : _vm._e(),
                  _vm.$_userMixins_isSchoolAdmin
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-clean btn-clean-primary btn-sm",
                          attrs: { to: { name: "GradeTemplateList" } },
                        },
                        [_vm._v(" Edit Templates ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.gradeTemplates.length > 0
              ? _c("div", { staticClass: "kt-portlet__body" }, [
                  _vm.allowGradeTemplateEditing
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-outline-info fade show w-100",
                        },
                        [
                          _vm._m(0),
                          _c("div", { staticClass: "alert-text" }, [
                            _vm._v(
                              " After changing a grade template, refresh all google data to apply the template. "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.allowGradeTemplateEditing
                    ? _c("div", { staticClass: "kt-portlet__content row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ml-4",
                            staticStyle: {
                              width: "600px",
                              "max-width": "400px",
                            },
                          },
                          [
                            _c("GradeTemplateCourseUpdater", {
                              staticClass: "w-100",
                              attrs: {
                                "original-grade-template-id":
                                  _vm.template?.gradeTemplateId || null,
                                "course-section-id": _vm.course.courseSectionId,
                                course: _vm.course,
                                index: 0,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__content" },
                    [
                      _c("GradeTemplateDescription", {
                        attrs: { template: _vm.template },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-questions-circular-button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }